import * as Styled from './headerImageWithTextStyles';
import React from 'react';

const HeaderImageWithText = ({ sectionData }) => {
return (
    <Styled.MainDiv>
            <Styled.Card>
                <Styled.Header1>{sectionData?.title}</Styled.Header1>
                <Styled.Header3>{sectionData?.text?.text}</Styled.Header3>
            </Styled.Card>
            <Styled.Image
                src={sectionData?.image?.url}
                alt={sectionData?.image?.title}
                />
    </Styled.MainDiv>
);
};

export default HeaderImageWithText;